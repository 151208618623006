import { enableGetSegmentsBatching, releaseListAPIMaxLimit } from '@gonfalon/dogfood-flags';
import { Environment } from '@gonfalon/environments';
import {
  type GetAllHoldoutsQueryParams,
  type GetAllPendingRequestsQueryParams,
  type GetAllReleasePipelinesQueryParams,
  type GetAllReleaseProgressionsForReleasePipelineQueryParams,
  type GetApplicationsQueryParams,
  type GetApplicationVersionsQueryParams,
  type GetApprovalRequestsQueryParams,
  type GetDiscoveredMetricEventsQueryParams,
  type GetEvaluationsUsageQueryParams,
  type GetExperimentQueryParams,
  type GetExperimentResultsQueryParams,
  type GetExperimentsAnyEnvQueryParams,
  type GetFeatureFlagQueryParams,
  type GetFeatureFlagsQueryParams,
  type GetFlagCountsForProjectsQueryParams,
  type GetHoldoutQueryParams,
  type GetLayersQueryParams,
  type GetMeasuredRolloutQueryParams,
  type GetMeasuredRolloutsQueryParams,
  type GetMemberQueryParams,
  type GetMembersQueryParams,
  type GetMetricGroupQueryParams,
  type GetMetricGroupsQueryParams,
  type GetMetricsQueryParams,
  type GetMigrationFlagMetricsQueryParams,
  type GetPayloadFiltersQueryParams,
  type GetProjectQueryParams,
  type GetProjectsQueryParams,
  type GetSegmentsQueryParams,
  type GetStatisticsQueryParams,
  type GetTagsQueryParams,
  type PostAuditLogEntriesQueryParams,
  type PostAuditLogEntriesRequestBody,
  type PostMigrationSafetyIssuesRequestBody,
  type RetrieveCleanupPostRequestBody,
  getAccount,
  getAccountsByEmail,
  getAccountSubscription,
  getActions,
  getAIConfig,
  getAIConfigs,
  getAllHoldouts,
  getAllPendingRequests,
  getAllReleasePipelines,
  getAllReleaseProgressionsForReleasePipeline,
  getApplication,
  getApplications,
  getApplicationVersionAdoption,
  getApplicationVersions,
  getApplicationVersionSupported,
  getApprovalRequest,
  getApprovalRequests,
  getAuditLogEntry,
  getBigSegmentStoreIntegration,
  getBigSegmentStoreIntegrations,
  getBillingPlans,
  getContextAttributeNames,
  getContextInstances,
  getContextInstanceSegmentsMembershipByEnv,
  getContextKindsByProjectKey,
  getCustomRole,
  getCustomRoles,
  getCustomWorkflow,
  getDependentFlags,
  getDependentFlagsByEnv,
  getDevice,
  getDiscoveredMetricEvents,
  getDomainVerifications,
  getEnvironment,
  getEnvironments,
  getEnvironmentsByProject,
  GetEnvironmentsByProjectQueryParams,
  getEvaluationsUsage,
  getEventActivity,
  getEventActivityByEnvironment,
  getEventActivityByProject,
  getExperiment,
  getExperimentAnalysis,
  GetExperimentAnalysisQueryParams,
  getExperimentationSettings,
  getExperimentResults,
  getExperiments,
  getExperimentsAnyEnv,
  GetExperimentsQueryParams,
  getFeatureFlag,
  getFeatureFlags,
  getFeatureFlagStatusAcrossEnvironments,
  getFeatureFlagTemplates,
  getFeatureFlagTemplatesForProject,
  getFeaturePreviewEnrollments,
  getFlagCountsForProjects,
  getFlagDefaultsByProject,
  getFlagFollowers,
  getFlagImportConfiguration,
  getFlagImportConfigurations,
  getFlagLinks,
  getFlagMeasuredRolloutConfiguration,
  getFollowersByProjEnv,
  getFollowPreferences,
  getHoldout,
  getHoldoutById,
  getIntegrationManifests,
  getIssue,
  getIssueErrors,
  getIssues,
  getLayers,
  getLimitPlans,
  getMeasuredRollout,
  getMeasuredRolloutMetricResults,
  getMeasuredRolloutMetricTimeSeriesResults,
  getMeasuredRollouts,
  getMember,
  getMembers,
  getMetric,
  getMetricEventInstances,
  getMetricGroup,
  getMetricGroups,
  GetMetricQueryParams,
  getMetrics,
  getMetricsAnalyticsQueryResult,
  getMigrationFlagMetrics,
  getPayloadFilter,
  getPayloadFilters,
  getProductAnalyticsContextsDimensionValues,
  getProductAnalyticsEventsDimensionValues,
  getProductAnalyticsMetricDimensions,
  getProductAnalyticsMetrics,
  getProfileContext,
  getProject,
  getProjects as generatedGetProjects,
  getProjects,
  getQuantileExperimentResults,
  GetQuantileExperimentResultsQueryParams,
  getRandomizationSettings,
  getReleaseByFlagKey,
  getReleasePipelineByKey,
  getRepositories,
  GetRepositoriesQueryParams,
  getSamlEncryptionCertificates,
  getSegment,
  getSegments,
  getSelectedEnvironments,
  getShortcutsByProject,
  getStatistics,
  getSubscription,
  getSubscriptionUsage,
  getTags,
  getTeam,
  getTeams,
  GetTeamsQueryParams,
  getTopSegmentTags,
  internalGetSessionReplaySessionDetails,
  internalGetSessionReplaySessions,
  patchProject,
  postAuditLogEntries,
  postFlagCleanup,
  postMetricsAnalyticsQueries,
  postMigrationSafetyIssues,
  retrieveCleanupPost,
  schemas,
  searchContextInstances,
  searchContexts,
} from '@gonfalon/openapi';
import { createQueryKeys, mergeQueryKeys } from '@lukemorales/query-key-factory';

import { createQueryOptions } from './createQueryOptions';
import { getMigrationFlagMetricsTaskRunner } from './getMigrationFlagMetricsTaskRunner';
import { getSegmentsTaskRunner } from './getSegmentsTaskRunner';
import { internalPostFlagsMeasuredRolloutStatusesTaskRunner } from './internalPostFlagsMeasuredRolloutStatusesTaskRunner';
import { internalSearchFlagsTaskRunner } from './internalSearchFlagsTaskRunner';
import { queryFeatureFlagStatusesTaskRunner } from './queryFeatureFlagStatusesTaskRunner';
import { reactQueryResponseAdapter } from './reactQueryResponseAdapter';
import type { FlagCleanupInput, JSONPatch, PostContextsParams, PostProductAnalyticsQueryRep } from './types';

export const auditLog = createQueryKeys('audit-log', {
  list: ({ spec, params }: { spec: PostAuditLogEntriesRequestBody; params?: PostAuditLogEntriesQueryParams }) => ({
    queryKey: params ? ['entries', spec, params] : ['entries', spec],
    queryFn: async () => reactQueryResponseAdapter(postAuditLogEntries({ body: spec, query: params })),
  }),
  detail: ({ id }: { id: string }) => ({
    queryKey: [id],
    queryFn: async ({ signal }) => reactQueryResponseAdapter(getAuditLogEntry({ id }, { signal })),
  }),
});

export const account = createQueryKeys('account', {
  detail: {
    queryKey: null,
    queryFn: async ({ signal }) => reactQueryResponseAdapter(getAccount({}, { signal })),
    contextQueries: {
      subscription: {
        queryKey: null,
        queryFn: async ({ signal }) => reactQueryResponseAdapter(getSubscription({}, { signal })),
      },
      legacySubscription: {
        queryKey: null,
        queryFn: async ({ signal }) => reactQueryResponseAdapter(getAccountSubscription({}, { signal })),
      },
      subscriptionUsage: {
        queryKey: null,
        queryFn: async ({ signal }) => reactQueryResponseAdapter(getSubscriptionUsage({}, { signal })),
      },
    },
  },
});

export const accountDetail = account.detail;
export const accountSubscription = account.detail._ctx.subscription;
export const accountLegacySubscription = account.detail._ctx.legacySubscription;

export const billingPlans = createQueryKeys('billing-plans', {
  list: {
    queryKey: null,
    queryFn: async ({ signal }) => reactQueryResponseAdapter(getBillingPlans({}, { signal })),
    contextQueries: {
      planLimits: ({ planType }: { planType: string }) => ({
        queryKey: [planType],
        queryFn: async ({ signal }) => reactQueryResponseAdapter(getLimitPlans({ planType }, { signal })),
      }),
    },
  },
});

export const actions = createQueryKeys('actions', {
  list: {
    queryKey: null,
    queryFn: async ({ signal }) => reactQueryResponseAdapter(getActions({}, { signal })),
  },
});

export const shortcuts = createQueryKeys('shortcuts', {
  list: ({ projectKey }: { projectKey: string }) => ({
    queryKey: [projectKey],
    queryFn: async ({ signal }) => reactQueryResponseAdapter(getShortcutsByProject({ projectKey }, { signal })),
  }),
});

export const shortcutsList = shortcuts.list;

export const migrationSafetyIssues = createQueryKeys('migration-safety-issues', {
  list: ({
    projectKey,
    flagKey,
    environmentKey,
    query,
  }: {
    projectKey: string;
    flagKey: string;
    environmentKey: string;
    query: PostMigrationSafetyIssuesRequestBody;
  }) => ({
    queryKey: [projectKey, flagKey, environmentKey, query],
    queryFn: async () =>
      reactQueryResponseAdapter(postMigrationSafetyIssues({ projectKey, flagKey, environmentKey, body: query })),
  }),
});

export const migrationMetrics = createQueryKeys('migration-metrics', {
  list: ({
    projectKey,
    flagKey,
    environmentKey,
    params,
  }: {
    projectKey: string;
    flagKey: string;
    environmentKey: string;
    params?: Omit<GetMigrationFlagMetricsQueryParams, 'rules'>;
  }) => ({
    queryKey: params ? [projectKey, flagKey, environmentKey, params] : [projectKey, flagKey, environmentKey],
    queryFn: async () =>
      reactQueryResponseAdapter(getMigrationFlagMetrics({ projectKey, flagKey, environmentKey, query: params })),
  }),
  detail: ({
    projectKey,
    flagKey,
    environmentKey,
    ruleId,
    params,
  }: {
    projectKey: string;
    flagKey: string;
    environmentKey: string;
    ruleId: string;
    params?: Omit<GetMigrationFlagMetricsQueryParams, 'rules'>;
  }) => ({
    queryKey: params
      ? [projectKey, flagKey, environmentKey, ruleId, params]
      : [projectKey, flagKey, environmentKey, ruleId],
    queryFn: async () => getMigrationFlagMetricsTaskRunner.run({ projectKey, flagKey, environmentKey, ruleId, params }),
  }),
});

export const cliDeviceCode = createQueryKeys('device-code', {
  detail: ({ deviceCode }: { deviceCode: string }) => ({
    queryKey: [deviceCode],
    queryFn: async ({ signal }) => reactQueryResponseAdapter(getDevice({ deviceCode }, { signal })),
  }),
});

export const flags = createQueryKeys('flags', {
  list: ({ projectKey, params }: { projectKey: string; params?: GetFeatureFlagsQueryParams }) => ({
    queryKey: [projectKey, ...(params ? [params] : [])],
    queryFn: async ({ signal }) =>
      reactQueryResponseAdapter(getFeatureFlags({ projectKey, query: params }, { signal })),
  }),
  cleanup: (input: FlagCleanupInput) => ({
    queryKey: [{ ...input }],
    queryFn: async () => reactQueryResponseAdapter(postFlagCleanup({ body: input })),
  }),
  getCleanup: (input: RetrieveCleanupPostRequestBody) => ({
    queryKey: [{ ...input }],
    queryFn: async () => reactQueryResponseAdapter(retrieveCleanupPost({ body: input })),
  }),
  detail: ({
    projectKey,
    flagKey,
    params,
  }: {
    projectKey: string;
    flagKey: string;
    params?: GetFeatureFlagQueryParams;
  }) => ({
    queryKey: params ? [projectKey, flagKey, params] : [projectKey, flagKey],
    queryFn: async () =>
      reactQueryResponseAdapter(getFeatureFlag({ projectKey, featureFlagKey: flagKey, query: params })),
    contextQueries: {
      allStatuses: {
        queryKey: null,
        queryFn: async () =>
          reactQueryResponseAdapter(getFeatureFlagStatusAcrossEnvironments({ projectKey, featureFlagKey: flagKey })),
      },
      evaluations: ({
        environmentKey,
        flagEvaluationParams,
      }: {
        environmentKey: string;
        flagEvaluationParams?: GetEvaluationsUsageQueryParams;
      }) => ({
        queryKey: flagEvaluationParams ? [environmentKey, flagEvaluationParams] : [environmentKey],
        queryFn: async () =>
          reactQueryResponseAdapter(
            getEvaluationsUsage({ projectKey, environmentKey, featureFlagKey: flagKey, query: flagEvaluationParams }),
          ),
      }),
      status: ({ environmentKeys }: { environmentKeys: string[] }) => ({
        queryKey: [environmentKeys],
        queryFn: async () => queryFeatureFlagStatusesTaskRunner.run({ projectKey, flagKey, environmentKeys }),
      }),
      followers: ({ environmentKey }: { environmentKey: string }) => ({
        queryKey: [environmentKey],
        queryFn: async () =>
          reactQueryResponseAdapter(getFlagFollowers({ projectKey, featureFlagKey: flagKey, environmentKey })),
      }),
      links: {
        queryKey: null,
        queryFn: async () => reactQueryResponseAdapter(getFlagLinks({ projectKey, featureFlagKey: flagKey })),
      },
      release: {
        queryKey: null,
        queryFn: async () => reactQueryResponseAdapter(getReleaseByFlagKey({ projectKey, flagKey })),
      },
      dependents: {
        queryKey: null,
        queryFn: async () => reactQueryResponseAdapter(getDependentFlags({ projectKey, featureFlagKey: flagKey })),
        contextQueries: {
          environments: ({ environmentKey }: { environmentKey: string }) => ({
            queryKey: [environmentKey],
            queryFn: async () =>
              reactQueryResponseAdapter(
                getDependentFlagsByEnv({ projectKey, featureFlagKey: flagKey, environmentKey }),
              ),
          }),
        },
      },
    },
  }),
  search: ({
    projectKey,
    flagKeys,
    environmentKeys,
  }: {
    projectKey: string;
    flagKeys: string[];
    environmentKeys: string[];
  }) => ({
    queryKey: [projectKey, { flagKeys, environmentKeys }],
    queryFn: async () => internalSearchFlagsTaskRunner.run({ projectKey, flagKeys, environmentKeys }),
  }),
  measuredRolloutConfiguration: (projectKey: string, flagKey: string) => ({
    queryKey: [{ projectKey, entity: 'measuredRolloutConfiguration', flagKey }],
    queryFn: async () => reactQueryResponseAdapter(getFlagMeasuredRolloutConfiguration({ projectKey, flagKey })),
  }),
});

export const flagsList = flags.list;
export const flagsDetail = flags.detail;
export const flagsDetailRoot = flags.detail._def;

export const flagsDetailLinks = ({
  projectKey,
  flagKey,
  params,
}: {
  projectKey: string;
  flagKey: string;
  params?: GetFeatureFlagQueryParams;
}) => flags.detail({ projectKey, flagKey, params })._ctx.links;

export const flagsDetailStatus = ({
  projectKey,
  flagKey,
  environmentKeys,
  params,
}: {
  projectKey: string;
  flagKey: string;
  environmentKeys: string[];
  params?: GetFeatureFlagQueryParams;
}) => flags.detail({ projectKey, flagKey, params })._ctx.status({ environmentKeys });

export const flagsDetailEvaluations = ({
  projectKey,
  flagKey,
  environmentKey,
  flagEvaluationParams,
}: {
  projectKey: string;
  flagKey: string;
  environmentKey: string;
  flagEvaluationParams?: GetEvaluationsUsageQueryParams;
}) => flags.detail({ projectKey, flagKey })._ctx.evaluations({ environmentKey, flagEvaluationParams });

export const layers = createQueryKeys('layers', {
  list: ({ projectKey, params }: { projectKey: string; params?: GetLayersQueryParams }) => ({
    queryKey: [projectKey, ...(params ? [params] : [])],
    queryFn: async ({ signal }) => reactQueryResponseAdapter(getLayers({ projectKey, query: params }, { signal })),
  }),
});

export const applications = createQueryKeys('applications', {
  list: ({ params }: { params?: GetApplicationsQueryParams }) => ({
    // Working around some limitations of query-key-factory here.
    //
    // [params] | null is not a valid type for queryKey, so we add a
    // placeholder string ('list') to satisfy the types.
    queryKey: params ? ['list', params] : ['list'],
    queryFn: async ({ signal }) => reactQueryResponseAdapter(getApplications({ query: params }, { signal })),
  }),
  detail: ({ applicationKey }: { applicationKey: string }) => ({
    queryKey: [applicationKey],
    queryFn: async ({ signal }) => reactQueryResponseAdapter(getApplication({ applicationKey }, { signal })),
    contextQueries: {
      versions: ({ params }: { params?: GetApplicationVersionsQueryParams }) => ({
        // Working around some limitations of query-key-factory here.
        //
        // [params] | null is not a valid type for queryKey, so we add a
        // placeholder string ('versions') to satisfy the types.
        queryKey: params ? ['versions', params] : ['versions'],
        queryFn: async () => reactQueryResponseAdapter(getApplicationVersions({ applicationKey, query: params })),
      }),
      versionCount: ({ params }: { params: { supported: boolean } }) => ({
        queryKey: [params],
        queryFn: async () =>
          reactQueryResponseAdapter(getApplicationVersionSupported({ applicationKey, query: params })),
      }),
      versionAdoption: {
        queryKey: null,
        queryFn: async ({ signal }) =>
          reactQueryResponseAdapter(getApplicationVersionAdoption({ applicationKey }, { signal })),
      },
    },
  }),
});

export const customRoles = createQueryKeys('customRoles', {
  list: {
    queryKey: null,
    queryFn: async ({ signal }) => reactQueryResponseAdapter(getCustomRoles({}, { signal })),
  },
  detail: ({ customRoleKey }: { customRoleKey: string }) => ({
    queryKey: [customRoleKey],
    queryFn: async ({ signal }) => reactQueryResponseAdapter(getCustomRole({ customRoleKey }, { signal })),
  }),
});

export const ssoDomains = createQueryKeys('ssoDomains', {
  list: {
    queryKey: null,
    queryFn: async ({ signal }) => reactQueryResponseAdapter(getDomainVerifications({}, { signal })),
  },
});

export const samlEncryptionCertificate = createQueryKeys('samlEncryptionCertificate', {
  list: {
    queryKey: null,
    queryFn: async ({ signal }) => reactQueryResponseAdapter(getSamlEncryptionCertificates({}, { signal })),
  },
});

export const pendingRequests = createQueryKeys('pendingRequests', {
  list: ({ kind, params }: { kind: string; params?: GetAllPendingRequestsQueryParams }) => ({
    queryKey: [kind, params],
    queryFn: async () => reactQueryResponseAdapter(getAllPendingRequests({ kind, query: params })),
  }),
});

export const segments = createQueryKeys('segments', {
  list: ({
    projectKey,
    environmentKey,
    params,
    batch = true,
  }: {
    projectKey: string;
    environmentKey: string;
    params?: GetSegmentsQueryParams;
    batch?: boolean;
  }) => ({
    queryKey: [projectKey, environmentKey, params],
    // enableGetSegmentsBatching is a feature flag tied to escalation SC-233978
    queryFn: async () =>
      enableGetSegmentsBatching() && batch
        ? getSegmentsTaskRunner.run({ projectKey, environmentKey, params })
        : reactQueryResponseAdapter(getSegments({ projectKey, environmentKey, query: params })),
    contextQueries: {
      topTags: () => ({
        queryKey: ['topTags', projectKey, environmentKey],
        queryFn: async ({ signal }) =>
          reactQueryResponseAdapter(getTopSegmentTags({ projectKey, environmentKey }, { signal })),
      }),
    },
  }),
  detail: ({
    projectKey,
    environmentKey,
    segmentKey,
    shouldIgnoreErrors,
  }: {
    projectKey: string;
    environmentKey: string;
    segmentKey: string;
    shouldIgnoreErrors?: boolean;
  }) => ({
    queryKey: [projectKey, environmentKey, segmentKey],
    queryFn: async ({ signal }): Promise<schemas['UserSegment']> => {
      try {
        const result = await reactQueryResponseAdapter(
          getSegment({ projectKey, environmentKey, segmentKey }, { signal }),
        );
        return result;
      } catch (error) {
        if (shouldIgnoreErrors) {
          return new Promise((resolve) => resolve(null as unknown as schemas['UserSegment']));
        }

        throw error;
      }
    },
  }),
});

export const segmentList = segments.list;

export const codeReferenceStatistics = createQueryKeys('code-reference-statistics', {
  list: ({ projectKey, params }: { projectKey: string; params?: GetStatisticsQueryParams }) => ({
    queryKey: params ? [projectKey, params] : [projectKey],
    queryFn: async () => reactQueryResponseAdapter(getStatistics({ projectKey, query: params })),
  }),
});

export const codeReferences = createQueryKeys('code-references', {
  list: ({ params }: { params?: GetRepositoriesQueryParams }) => ({
    queryKey: params ? ['repositories', params] : ['repositories'],
    queryFn: async () => reactQueryResponseAdapter(getRepositories({ query: params })),
  }),
});

export const projects = createQueryKeys('projects', {
  list: ({ params }: { params?: GetProjectsQueryParams }) => ({
    // Working around some limitations of query-key-factory here.
    //
    // [params] | null is not a valid type for queryKey, so we add a
    // placeholder string ('list') to satisfy the types.
    queryKey: params ? ['list', params] : ['list'],
    queryFn: async () => reactQueryResponseAdapter(getProjects({ query: params })),
  }),
  /**
   * @deprecated please use projectsDetail query instead
   */
  detail: ({ projectKey, params }: { projectKey: string; params?: GetProjectQueryParams }) => ({
    queryKey: params ? [projectKey, params] : [projectKey],
    queryFn: async () =>
      reactQueryResponseAdapter(
        getProject({
          projectKey,
          query: params,
        }),
      ),
    contextQueries: {
      flagDefaults: {
        queryKey: null,
        queryFn: async ({ signal }) => reactQueryResponseAdapter(getFlagDefaultsByProject({ projectKey }, { signal })),
      },
      experimentationSettings: {
        queryKey: null,
        queryFn: async ({ signal }) =>
          reactQueryResponseAdapter(getExperimentationSettings({ projectKey }, { signal })),
      },
      randomizationSettings: {
        queryKey: null,
        queryFn: async ({ signal }) => reactQueryResponseAdapter(getRandomizationSettings({ projectKey }, { signal })),
      },
      followers({ environmentKey }: { environmentKey: string }) {
        return {
          queryKey: [environmentKey],
          queryFn: async ({ signal }) =>
            reactQueryResponseAdapter(getFollowersByProjEnv({ projectKey, environmentKey }, { signal })),
        };
      },
    },
  }),
  update: ({ projectKey, input }: { projectKey: string; input: { patch: JSONPatch } }) => ({
    queryKey: [projectKey],
    queryFn: async ({ signal }) =>
      reactQueryResponseAdapter(patchProject({ projectKey, body: input.patch }, { signal })),
  }),
});

export const projectsList = projects.list;
export const projectsDetail = createQueryOptions(getProject);

export const environments = createQueryKeys('environments', {
  list: ({ projectKey, params }: { projectKey: string; params?: GetEnvironmentsByProjectQueryParams }) => ({
    queryKey: params ? [projectKey, params] : [projectKey],
    queryFn: async ({ signal }) =>
      reactQueryResponseAdapter(getEnvironmentsByProject({ projectKey, query: params }, { signal })),
  }),
  detail: ({
    projectKey,
    environmentKey,
    shouldIgnoreErrors,
  }: {
    projectKey: string;
    environmentKey: string;
    shouldIgnoreErrors?: boolean;
  }) => ({
    queryKey: [projectKey, environmentKey],
    queryFn: async ({ signal }): Promise<Environment> => {
      try {
        return reactQueryResponseAdapter(getEnvironment({ projectKey, environmentKey }, { signal }));
      } catch (error) {
        if (shouldIgnoreErrors) {
          return new Promise((resolve) => resolve(null as unknown as Environment));
        }

        throw error;
      }
    },
  }),
});

export const environmentsList = environments.list;
export const environmentsDetail = environments.detail;

export const internalEnvironmentList = createQueryOptions(getEnvironments);

export const selectedEnvironments = createQueryKeys('selected-environments', {
  all: ({ projectKey }: { projectKey: string }) => ({
    queryKey: [projectKey],
    queryFn: async ({ signal }) => reactQueryResponseAdapter(getSelectedEnvironments({ projectKey }, { signal })),
  }),
});

export const profileContext = createQueryKeys('profileContext', {
  detail: {
    queryKey: null,
    queryFn: async ({ signal }) => reactQueryResponseAdapter(getProfileContext({}, { signal })),
  },
});

export const releasePipelines = createQueryKeys('release-pipelines', {
  list: ({ projectKey, params }: { projectKey: string; params?: GetAllReleasePipelinesQueryParams }) => ({
    queryKey: params ? [projectKey, params] : [projectKey],
    queryFn: async () => reactQueryResponseAdapter(getAllReleasePipelines({ projectKey, query: params })),
  }),
  detail: ({ projectKey, releasePipelineKey }: { projectKey: string; releasePipelineKey: string }) => ({
    queryKey: [projectKey, releasePipelineKey],
    queryFn: async ({ signal }) =>
      reactQueryResponseAdapter(getReleasePipelineByKey({ projectKey, pipelineKey: releasePipelineKey }, { signal })),
    contextQueries: {
      releaseProgressions: (props: { params?: GetAllReleaseProgressionsForReleasePipelineQueryParams } = {}) => {
        const { params } = props;
        return {
          // Working around some limitations of query-key-factory here.
          //
          // [params] | null is not a valid type for queryKey, so we add a
          // placeholder string ('releaseProgressions') to satisfy the types.
          queryKey: params ? ['releaseProgressions', params] : ['releaseProgressions'],
          queryFn: async () =>
            reactQueryResponseAdapter(
              getAllReleaseProgressionsForReleasePipeline({
                projectKey,
                pipelineKey: releasePipelineKey,
                query: params,
              }),
            ),
          contextQueries: {
            paginate: {
              queryKey: ['paginate'],
              queryFn: async (ctx) => {
                const { limit = releaseListAPIMaxLimit() } = params ?? {};
                const { pageParam: offset = 0 } = ctx as { pageParam?: number };
                return reactQueryResponseAdapter(
                  getAllReleaseProgressionsForReleasePipeline({
                    projectKey,
                    pipelineKey: releasePipelineKey,
                    query: { ...(params ?? {}), limit, offset },
                  }),
                );
              },
            },
          },
        };
      },
    },
  }),
});

export const releasePipelinesRoot = releasePipelines._def;
export const releasePipelinesList = releasePipelines.list;
export const releasePipelinesDetail = releasePipelines.detail;

export const releasePipelinesDetailReleaseProgressions = ({
  projectKey,
  releasePipelineKey,
  params = {},
}: {
  projectKey: string;
  releasePipelineKey: string;
  params?: GetAllReleaseProgressionsForReleasePipelineQueryParams;
}) => releasePipelines.detail({ projectKey, releasePipelineKey })._ctx.releaseProgressions({ params });

export const members = createQueryKeys('members', {
  list: ({ params }: { params?: GetMembersQueryParams }) => ({
    queryKey: params ? ['collection', params] : ['collection'],
    queryFn: async ({ signal }) => reactQueryResponseAdapter(getMembers({ query: params }, { signal })),
  }),
  profile: {
    queryKey: null,
    queryFn: async ({ signal }) => reactQueryResponseAdapter(getMember({ id: 'me' }, { signal })),
  },
  detail: ({ memberId, params }: { memberId: string; params?: GetMemberQueryParams }) => ({
    queryKey: params ? [memberId, params] : [memberId],
    queryFn: async ({ signal }) => reactQueryResponseAdapter(getMember({ id: memberId, query: params }, { signal })),
  }),
});

export const membersProfile = members.profile;

export const membersList = members.list;
export const membersDetail = members.detail;

export const followPreferences = createQueryKeys('followPreferences', {
  detail: {
    queryKey: null,
    queryFn: async ({ signal }) => reactQueryResponseAdapter(getFollowPreferences({}, { signal })),
  },
});

export const organizations = createQueryKeys('organizations', {
  list: ({ email }: { email: string }) => ({
    queryKey: [email],
    queryFn: async ({ signal }) => reactQueryResponseAdapter(getAccountsByEmail({ email }, { signal })),
  }),
});

export const payloadFilter = createQueryKeys('payloadFilter', {
  list: ({ projectKey, params }: { projectKey: string; params?: GetPayloadFiltersQueryParams }) => ({
    queryKey: params ? [projectKey, params] : [projectKey],
    queryFn: async () => reactQueryResponseAdapter(getPayloadFilters({ projectKey, query: params })),
  }),
  detail: ({ projectKey, payloadFilterKey }: { projectKey: string; payloadFilterKey: string }) => ({
    queryKey: [projectKey, payloadFilterKey],
    queryFn: async ({ signal }) =>
      reactQueryResponseAdapter(getPayloadFilter({ projectKey, payloadFilterKey }, { signal })),
  }),
});

export const metrics = createQueryKeys('metrics', {
  list: ({ projectKey, params }: { projectKey: string; params?: GetMetricsQueryParams }) => ({
    queryKey: params ? [projectKey, params] : [projectKey],
    queryFn: async () => reactQueryResponseAdapter(getMetrics({ projectKey, query: params })),
  }),
  detail: ({
    projectKey,
    metricKey,
    params,
  }: {
    projectKey: string;
    metricKey: string;
    params?: GetMetricQueryParams;
  }) => ({
    queryKey: params ? [projectKey, metricKey, params] : [projectKey, metricKey],
    queryFn: async () => reactQueryResponseAdapter(getMetric({ projectKey, metricKey, query: params })),
  }),
  events: ({
    projectKey,
    environmentKey,
    metricKey,
    offset,
    limit,
    uptoTime,
  }: {
    projectKey: string;
    environmentKey: string;
    metricKey: string;
    offset?: number;
    limit?: number;
    uptoTime?: number;
  }) => ({
    queryKey: [projectKey, environmentKey, metricKey, offset, limit, uptoTime],
    queryFn: async ({ signal }) =>
      reactQueryResponseAdapter(
        getMetricEventInstances(
          { projectKey, metricKey, query: { offset, limit, uptoTime, environmentKey } },
          { signal },
        ),
      ),
  }),
});

export const metricsList = metrics.list;
export const metricsDetail = metrics.detail;
export const metricsEvents = metrics.events;

export const metricGroups = createQueryKeys('metric-groups', {
  list: ({ projectKey, params }: { projectKey: string; params?: GetMetricGroupsQueryParams }) => ({
    queryKey: params ? [projectKey, params] : [projectKey],
    queryFn: async () => reactQueryResponseAdapter(getMetricGroups({ projectKey, query: params })),
  }),
  detail: ({
    projectKey,
    metricGroupKey,
    params,
  }: {
    projectKey: string;
    metricGroupKey: string;
    params?: GetMetricGroupQueryParams;
  }) => ({
    queryKey: params ? [projectKey, metricGroupKey, params] : [projectKey, metricGroupKey],
    queryFn: async () => reactQueryResponseAdapter(getMetricGroup({ projectKey, metricGroupKey, query: params })),
  }),
});

export const experiments = createQueryKeys('experiments', {
  anyEnv: ({ projectKey, params }: { projectKey: string; params?: GetExperimentsAnyEnvQueryParams }) => ({
    queryKey: params ? [projectKey, params] : [projectKey],
    queryFn: async () => reactQueryResponseAdapter(getExperimentsAnyEnv({ projectKey, query: params })),
  }),
  list: ({
    projectKey,
    environmentKey,
    params,
  }: {
    projectKey: string;
    environmentKey: string;
    params?: GetExperimentsQueryParams;
  }) => ({
    queryKey: params ? [projectKey, environmentKey, params] : [projectKey, environmentKey],
    queryFn: async () => reactQueryResponseAdapter(getExperiments({ projectKey, environmentKey, query: params })),
  }),
  detail: ({
    projectKey,
    environmentKey,
    experimentKey,
    params,
  }: {
    projectKey: string;
    environmentKey: string;
    experimentKey: string;
    params?: GetExperimentQueryParams;
  }) => ({
    queryKey: params
      ? [projectKey, environmentKey, experimentKey, params]
      : [projectKey, environmentKey, experimentKey],
    queryFn: async () =>
      reactQueryResponseAdapter(getExperiment({ projectKey, environmentKey, experimentKey, query: params })),
    contextQueries: {
      results: ({
        metricKey,
        params: resultsParams,
      }: {
        metricKey: string;
        params?: GetExperimentResultsQueryParams;
      }) => ({
        queryKey: params ? [metricKey, params] : [metricKey],
        queryFn: async () =>
          reactQueryResponseAdapter(
            getExperimentResults({ projectKey, environmentKey, experimentKey, metricKey, query: resultsParams }),
          ),
      }),
      percentileResults: ({
        metricKey,
        params: percentileResultsParams,
      }: {
        metricKey: string;
        params?: GetQuantileExperimentResultsQueryParams;
      }) => ({
        queryKey: params ? [metricKey, params] : [metricKey],
        queryFn: async () =>
          reactQueryResponseAdapter(
            getQuantileExperimentResults({
              projectKey,
              environmentKey,
              experimentKey,
              metricKey,
              query: percentileResultsParams,
            }),
          ),
      }),
      analysis: ({
        metricKey,
        params: analysisParams,
      }: {
        metricKey: string;
        params?: GetExperimentAnalysisQueryParams;
      }) => ({
        queryKey: analysisParams ? [metricKey, analysisParams] : [metricKey],
        queryFn: async () =>
          reactQueryResponseAdapter(
            getExperimentAnalysis({ projectKey, environmentKey, experimentKey, metricKey, query: analysisParams }),
          ),
      }),
    },
  }),
});

export const experimentsList = experiments.list;

export const holdouts = createQueryKeys('holdouts', {
  list: ({
    projectKey,
    environmentKey,
    params,
  }: {
    projectKey: string;
    environmentKey: string;
    params?: GetAllHoldoutsQueryParams;
  }) => ({
    queryKey: params ? [projectKey, environmentKey, params] : [projectKey, environmentKey],
    queryFn: async ({ signal }) =>
      reactQueryResponseAdapter(getAllHoldouts({ projectKey, environmentKey, query: params }, { signal })),
  }),
  detail: ({
    projectKey,
    environmentKey,
    holdoutKey,
    params,
  }: {
    projectKey: string;
    environmentKey: string;
    holdoutKey: string;
    params?: GetHoldoutQueryParams;
  }) => ({
    queryKey: [projectKey, environmentKey, holdoutKey, params],
    queryFn: async () =>
      reactQueryResponseAdapter(getHoldout({ projectKey, environmentKey, holdoutKey, query: params })),
  }),
  detailById: ({
    projectKey,
    environmentKey,
    holdoutId,
  }: {
    projectKey: string;
    environmentKey: string;
    holdoutId: string;
  }) => ({
    queryKey: [projectKey, environmentKey, holdoutId],
    queryFn: async ({ signal }) =>
      reactQueryResponseAdapter(getHoldoutById({ projectKey, environmentKey, holdoutId }, { signal })),
  }),
});

export const holdoutsList = holdouts.list;
export const holdoutsListRoot = holdouts.list._def;
export const holdoutsDetailRoot = holdouts.detail._def;
export const holdoutsDetail = holdouts.detail;

export const measuredRollouts = createQueryKeys('measured-rollouts', {
  list: ({
    projectKey,
    flagKey,
    params,
  }: {
    projectKey: string;
    flagKey: string;
    params?: GetMeasuredRolloutsQueryParams;
  }) => ({
    queryKey: params ? [projectKey, flagKey, params] : [projectKey, flagKey],
    queryFn: async () => reactQueryResponseAdapter(getMeasuredRollouts({ flagKey, projectKey, query: params })),
  }),
  detail: ({
    projectKey,
    flagKey,
    environmentKey,
    measuredRolloutId,
    params,
  }: {
    projectKey: string;
    flagKey: string;
    environmentKey: string;
    measuredRolloutId: string;
    params?: GetMeasuredRolloutQueryParams;
  }) => ({
    queryKey: params
      ? [projectKey, flagKey, environmentKey, measuredRolloutId, params]
      : [projectKey, flagKey, environmentKey, measuredRolloutId],
    queryFn: async () =>
      reactQueryResponseAdapter(
        getMeasuredRollout({
          flagKey,
          projectKey,
          environmentKey,
          measuredRolloutId,
          query: {
            ...params,
            expand: [...(params?.expand ?? []), 'events', 'metrics'], // We always want these for now.
          },
        }),
      ),
  }),
  metricResults: ({
    projectKey,
    flagKey,
    environmentKey,
    measuredRolloutId,
    metricKey,
  }: {
    projectKey: string;
    flagKey: string;
    environmentKey: string;
    measuredRolloutId: string;
    metricKey: string;
  }) => ({
    queryKey: [projectKey, flagKey, environmentKey, measuredRolloutId, metricKey],
    queryFn: async () =>
      reactQueryResponseAdapter(
        getMeasuredRolloutMetricResults({ projectKey, flagKey, environmentKey, measuredRolloutId, metricKey }),
      ),
  }),
  metricTimeSeriesResults: ({
    projectKey,
    flagKey,
    environmentKey,
    measuredRolloutId,
    metricKey,
  }: {
    projectKey: string;
    flagKey: string;
    environmentKey: string;
    measuredRolloutId: string;
    metricKey: string;
  }) => ({
    queryKey: [projectKey, flagKey, environmentKey, measuredRolloutId, metricKey],
    queryFn: async ({ signal }) =>
      reactQueryResponseAdapter(
        getMeasuredRolloutMetricTimeSeriesResults(
          { projectKey, flagKey, environmentKey, measuredRolloutId, metricKey },
          { signal },
        ),
      ),
  }),
  measuredRolloutStatus: ({
    projectKey,
    environmentKey,
    flagKey,
  }: {
    projectKey: string;
    environmentKey: string;
    flagKey: string;
  }) => ({
    queryKey: [environmentKey, flagKey],
    queryFn: async () =>
      internalPostFlagsMeasuredRolloutStatusesTaskRunner.run({ projectKey, flagKey, environmentKey }),
  }),
});

export const measuredRolloutsList = measuredRollouts.list;
export const measuredRolloutsDetail = measuredRollouts.detail;
export const measuredRolloutsMetricResults = measuredRollouts.metricResults;
export const measuredRolloutsMetricTimeSeriesResults = measuredRollouts.metricTimeSeriesResults;
export const measuredRolloutsMeasuredRolloutStatus = measuredRollouts.measuredRolloutStatus;

export const metricAnalytics = createQueryKeys('metric-analytics', {
  series: ({
    projectKey,
    environmentKey,
    query,
  }: {
    projectKey: string;
    environmentKey: string;
    query: PostProductAnalyticsQueryRep;
  }) => ({
    queryKey: [projectKey, environmentKey, query],
    queryFn: async ({ signal }) =>
      reactQueryResponseAdapter(postMetricsAnalyticsQueries({ projectKey, environmentKey, body: query }, { signal })),
  }),
  queryResult: ({
    projectKey,
    environmentKey,
    analyticsQueryResultId,
  }: {
    projectKey: string;
    environmentKey: string;
    analyticsQueryResultId: string;
  }) => ({
    queryKey: [projectKey, environmentKey, analyticsQueryResultId],
    queryFn: async ({ signal }) =>
      reactQueryResponseAdapter(
        getMetricsAnalyticsQueryResult({ projectKey, environmentKey, analyticsQueryResultId }, { signal }),
      ),
  }),
  metrics: ({
    projectKey,
    environmentKey,
    startsWith,
    limit = 20,
  }: {
    projectKey: string;
    environmentKey: string;
    startsWith?: string;
    limit?: number;
  }) => ({
    queryKey: [projectKey, environmentKey, startsWith, limit],
    queryFn: async ({ signal }) =>
      reactQueryResponseAdapter(
        getProductAnalyticsMetrics({ projectKey, environmentKey, query: { startsWith, limit } }, { signal }),
      ),
  }),
  metricDimensions: ({
    projectKey,
    environmentKey,
    metricKey,
    startsWith,
    limit = 20,
  }: {
    projectKey: string;
    environmentKey: string;
    metricKey: string;
    startsWith?: string;
    limit?: number;
  }) => ({
    queryKey: [projectKey, environmentKey, metricKey, startsWith, limit],
    queryFn: async ({ signal }) =>
      reactQueryResponseAdapter(
        getProductAnalyticsMetricDimensions(
          { projectKey, environmentKey, metricKey, query: { startsWith, limit } },
          { signal },
        ),
      ),
  }),
  metricDimensionValues: ({
    contextKindKey,
    dimensionKey,
    environmentKey,
    limit = 20,
    metricKey,
    projectKey,
    startsWith,
  }: {
    contextKindKey?: string;
    dimensionKey: string;
    environmentKey: string;
    limit?: number;
    metricKey: string;
    projectKey: string;
    startsWith?: string;
  }) => ({
    queryKey: [projectKey, environmentKey, metricKey, contextKindKey, dimensionKey, startsWith, limit],
    queryFn: async ({ signal }) =>
      contextKindKey
        ? reactQueryResponseAdapter(
            getProductAnalyticsContextsDimensionValues(
              {
                projectKey,
                environmentKey,
                metricKey,
                contextKindKey,
                dimensionKey,
                query: { startsWith, limit },
              },
              {
                signal,
              },
            ),
          )
        : reactQueryResponseAdapter(
            getProductAnalyticsEventsDimensionValues(
              { projectKey, environmentKey, metricKey, dimensionKey, query: { startsWith, limit } },
              { signal },
            ),
          ),
  }),
});

export const bigSegmentStore = createQueryKeys('bigSegmentStore', {
  list: {
    queryKey: null,
    queryFn: async ({ signal }) => reactQueryResponseAdapter(getBigSegmentStoreIntegrations({}, { signal })),
  },
  detail: ({
    projectKey,
    environmentKey,
    integrationKey,
    integrationId,
  }: {
    projectKey: string;
    environmentKey: string;
    integrationKey: string;
    integrationId: string;
  }) => ({
    queryKey: [projectKey, environmentKey, integrationKey, integrationId],
    queryFn: async ({ signal }) =>
      reactQueryResponseAdapter(
        getBigSegmentStoreIntegration({ projectKey, environmentKey, integrationKey, integrationId }, { signal }),
      ),
  }),
});

export const flagTemplates = createQueryKeys('flag-templates', {
  list: {
    queryKey: null,
    queryFn: async ({ signal }) => reactQueryResponseAdapter(getFeatureFlagTemplates({}, { signal })),
  },
});

export const internalFlagTemplates = createQueryKeys('internal-flag-templates', {
  list: ({ projKey }: { projKey: string }) => ({
    queryKey: [projKey],
    queryFn: async ({ signal }) =>
      reactQueryResponseAdapter(getFeatureFlagTemplatesForProject({ projKey }, { signal })),
  }),
});

export const internalProjectsWithFlagCount = createQueryKeys('internalProjectsWithFlagCount', {
  list: ({ params }: { params?: GetFlagCountsForProjectsQueryParams }) => ({
    // Working around some limitations of query-key-factory here.
    //
    // [params] | null is not a valid type for queryKey, so we add a
    // placeholder string ('list') to satisfy the types.
    queryKey: params ? ['list', params] : ['list'],
    queryFn: async () => reactQueryResponseAdapter(getFlagCountsForProjects({ query: params })),
  }),
});

export const tags = createQueryKeys('tags', {
  list: ({ params }: { params?: GetTagsQueryParams }) => ({
    // Working around some limitations of query-key-factory here.
    //
    // [params] | null is not a valid type for queryKey, so we add a
    // placeholder string ('list') to satisfy the types.
    queryKey: params ? ['collection', params] : ['collection'],
    queryFn: async ({ signal }) => reactQueryResponseAdapter(getTags({ query: params }, { signal })),
  }),
});

export const tagsList = tags.list;

export const teams = createQueryKeys('teams', {
  list: ({ params }: { params?: GetTeamsQueryParams }) => ({
    queryKey: params ? ['collection', params] : ['collection'],
    queryFn: async ({ signal }) => reactQueryResponseAdapter(getTeams({ query: params }, { signal })),
  }),
  detail: ({ teamKey }: { teamKey: string }) => ({
    queryKey: [teamKey],
    queryFn: async ({ signal }) => reactQueryResponseAdapter(getTeam({ teamKey }, { signal })),
  }),
});

export const teamsList = teams.list;
export const teamsDetail = teams.detail;

export const discoveredMetricEvents = createQueryKeys('discovered-metric-events', {
  list: ({
    projectKey,
    environmentKey,
    params,
  }: {
    projectKey: string;
    environmentKey: string;
    params?: GetDiscoveredMetricEventsQueryParams;
  }) => ({
    queryKey: params ? [projectKey, environmentKey, params] : [projectKey, environmentKey],
    queryFn: async () =>
      reactQueryResponseAdapter(getDiscoveredMetricEvents({ projectKey, environmentKey, query: params })),
  }),
});

export const contexts = createQueryKeys('contexts', {
  list: ({
    projectKey,
    environmentKey,
    params,
  }: {
    projectKey: string;
    environmentKey: string;
    params: PostContextsParams;
  }) => ({
    queryKey: [projectKey, environmentKey, params],
    queryFn: async ({ signal }) =>
      reactQueryResponseAdapter(searchContexts({ projectKey, environmentKey, body: params }, { signal })),
  }),
});

export const searchContextInstancesQuery = createQueryOptions(searchContextInstances);
export const contextInstanceQuery = createQueryOptions(getContextInstances);
export const getContextInstanceSegmentMembershipQuery = createQueryOptions(getContextInstanceSegmentsMembershipByEnv);
export const getContextAttributeNamesQuery = createQueryOptions(getContextAttributeNames);

export const contextKinds = createQueryKeys('contextKinds', {
  list: ({ projectKey }: { projectKey: string }) => ({
    queryKey: [projectKey],
    queryFn: async ({ signal }) => reactQueryResponseAdapter(getContextKindsByProjectKey({ projectKey }, { signal })),
  }),
});

export const contextKindsList = contextKinds.list;

export const workflows = createQueryKeys('workflows', {
  detail: ({
    projectKey,
    featureFlagKey,
    environmentKey,
    workflowId,
  }: {
    projectKey: string;
    featureFlagKey: string;
    environmentKey: string;
    workflowId: string;
  }) => ({
    queryKey: [projectKey, featureFlagKey, environmentKey, workflowId],
    queryFn: async ({ signal }) =>
      reactQueryResponseAdapter(
        getCustomWorkflow({ projectKey, featureFlagKey, environmentKey, workflowId }, { signal }),
      ),
  }),
});

export const integrationManifests = createQueryKeys('integrationManifests', {
  list: {
    queryKey: null,
    queryFn: async ({ signal }) => reactQueryResponseAdapter(getIntegrationManifests({}, { signal })),
  },
});

export const featurePreviews = createQueryKeys('featurePreviews', {
  list: {
    queryKey: null,
    queryFn: async ({ signal }) => reactQueryResponseAdapter(getFeaturePreviewEnrollments({}, { signal })),
  },
});

export const eventActivity = createQueryKeys('eventActivity', {
  byEventKey: ({ projectKey, eventKey, params }) => ({
    queryKey: [projectKey, eventKey, params],
    queryFn: async ({ signal }) =>
      reactQueryResponseAdapter(getEventActivity({ projectKey, eventKey, query: params }, { signal })),
  }),
});

export const eventActivityByEnv = createQueryKeys('eventActivityByEnv', {
  byEnvKey: ({ projectKey, environmentKey, params }) => ({
    queryKey: [projectKey, environmentKey, params],
    queryFn: async ({ signal }) =>
      reactQueryResponseAdapter(
        getEventActivityByEnvironment({ projectKey, environmentKey, query: params }, { signal }),
      ),
  }),
});

export const eventActivityByProj = createQueryKeys('eventActivityByProj', {
  byProjKey: ({ projectKey, params }) => ({
    queryKey: [projectKey, params],
    queryFn: async ({ signal }) =>
      reactQueryResponseAdapter(getEventActivityByProject({ projectKey, query: params }, { signal })),
  }),
});

export const approvalRequests = createQueryKeys('approvalRequests', {
  list: ({ params }: { params?: GetApprovalRequestsQueryParams }) => ({
    queryKey: params ? ['filtered', params] : ['all'],
    queryFn: async ({ signal }) => reactQueryResponseAdapter(getApprovalRequests({ query: params }, { signal })),
  }),
  detail: ({ approvalRequestId }: { approvalRequestId: string }) => ({
    queryKey: [{ approvalRequestId }],
    queryFn: async ({ signal }) => reactQueryResponseAdapter(getApprovalRequest({ id: approvalRequestId }, { signal })),
  }),
});

export const approvalRequestsList = approvalRequests.list;
export const approvalRequestsDetail = approvalRequests.detail;

export const sessionReplay = createQueryKeys('sessionReplay', {
  list: ({ projectKey, environmentKey }: { projectKey: string; environmentKey: string }) => ({
    queryKey: [projectKey, environmentKey],
    queryFn: async ({ signal }) =>
      reactQueryResponseAdapter(internalGetSessionReplaySessions({ projectKey, environmentKey }, { signal })),
  }),
  detail: ({
    projectKey,
    environmentKey,
    sessionId,
  }: {
    projectKey: string;
    environmentKey: string;
    sessionId: string;
  }) => ({
    queryKey: [projectKey, environmentKey, sessionId],
    queryFn: async ({ signal }) =>
      reactQueryResponseAdapter(
        internalGetSessionReplaySessionDetails({ projectKey, environmentKey, sessionId }, { signal }),
      ),
  }),
});

export const monitoringIssuesList = createQueryOptions(getIssues);
export const monitoringIssuesDetail = createQueryOptions(getIssue);

export const monitoringErrorsList = createQueryOptions(getIssueErrors);

export const flagImport = createQueryKeys('flagImport', {
  list: {
    queryKey: null,
    queryFn: async ({ signal }) => reactQueryResponseAdapter(getFlagImportConfigurations({}, { signal })),
  },
  detail: ({
    projectKey,
    integrationKey,
    integrationId,
  }: {
    projectKey: string;
    integrationKey: string;
    integrationId: string;
  }) => ({
    queryKey: [projectKey, integrationKey, integrationId],
    queryFn: async ({ signal }) =>
      reactQueryResponseAdapter(getFlagImportConfiguration({ projectKey, integrationKey, integrationId }, { signal })),
  }),
});

export const projectsQuery = createQueryOptions(generatedGetProjects);

export const aiConfigs = createQueryKeys('ai-configs', {
  list: ({ projectKey }: { projectKey: string }) => ({
    queryKey: [projectKey],
    queryFn: async ({ signal }) =>
      reactQueryResponseAdapter(getAIConfigs({ projectKey, header: { 'LD-API-Version': 'beta' } }, { signal })),
  }),
  detail: ({ projectKey, aiConfigKey }: { projectKey: string; aiConfigKey: string }) => ({
    queryKey: [projectKey, aiConfigKey],
    queryFn: async ({ signal }) =>
      reactQueryResponseAdapter(
        getAIConfig({ projectKey, configKey: aiConfigKey, header: { 'LD-API-Version': 'beta' } }, { signal }),
      ),
  }),
});

export const queries = mergeQueryKeys(
  approvalRequests,
  account,
  actions,
  applications,
  auditLog,
  codeReferenceStatistics,
  discoveredMetricEvents,
  environments,
  experiments,
  holdouts,
  flags,
  measuredRollouts,
  members,
  metrics,
  metricGroups,
  migrationMetrics,
  migrationSafetyIssues,
  projects,
  releasePipelines,
  selectedEnvironments,
  bigSegmentStore,
  payloadFilter,
  flagTemplates,
  internalFlagTemplates,
  segments,
  tags,
  teams,
  contexts,
  contextKinds,
  ssoDomains,
  workflows,
  followPreferences,
  integrationManifests,
  shortcuts,
  flagImport,
  aiConfigs,
);
